
import { Col, Row, Tooltip, Tab, Tabs, Modal, OverlayTrigger } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useService from "@hooks/useService";

import ButtonOw from "@components/ButtonOw";
import PaginationOw from '@components/PaginationOw'
import Loading from '@components/Loading'
import { toast } from "react-toastify";
import { formatDate } from "../../../../helpers";


export default function ProjectDetailsEmployee() {
    const newDate = new Date();
    let ano = newDate.getFullYear();
    let mes = newDate.getMonth() + 1;
    if (mes < 10) {
        mes = '0' + mes
    }
    let reference = `${ano}-${mes}`
    const params = useParams()
    const projects = useService('projects');
    const projectHours = useService('projectHours');
    const projectUsers = useService('projectUsers');
    const [page, setPage] = useState(1)
    const [newReference, setNewReference] = useState(reference)
    const [loadingSave, setLoadingSave] = useState(false)
    const [project, setProject] = useState();
    const [init_date_time, setInit] = useState();
    const [end_date_time, setEnd] = useState();
    const [description, setDescription] = useState();
    const [paginationData, setPaginationData] = useState({
        totalRegisters: 0
    });
    const [removeUserId, setRemoveUserId] = useState()
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
    const [showNewUser, setShowUser] = useState(false);
    const handleCloseUser = () => setShowUser(false);
    const handleShowUser = () => setShowUser(true);

    const tooltip = (name) => {
        return (
            <Tooltip id="tooltip">
                <strong>{name}</strong>
            </Tooltip>
        );
    };

    useEffect(() => {
        fetchProject();
    }, []);

    const [users, setUsers] = useState(null)


    async function fetchProject() {
        try {
            // Executa ambas as requisições em paralelo e aguarda o resultado de ambas
            const [projectResult, hoursResult] = await Promise.all([
                projects.get(params.id),
                projectHours.list(`project_id=${params.id}&reference=${newReference}`),
            ]);


            setProject(projectResult.data);
            setPaginationData(hoursResult.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao carregar dados:", error);
            toast.error("Erro ao carregar dados do projeto");
            setLoading(false);
        }
    }

    function loadingPage() {
        return (
            <div className="d-flex justify-content-center align-itens-center mt-5 mb-5">
                <Loading variant='secondary' />
            </div>
        )
    }

    function showModalDelete(id) {
        setRemoveUserId(id)
        handleShow()
    }

    async function removeUser() {
        setLoadingSave(true)
        try {
            const res = await projectHours.delete(removeUserId)
            if (!res.error) {
                toast.success('Hora apagada com sucesso')
            } else {
                toast.error('Erro ao apagar hora')
            }
            handleClose()
            fetchProject()
        } catch (err) {
            toast.error(err)
        }
        setLoadingSave(false)
    }
    async function addHour() {
        setLoadingSave(true)
        try {
            const dateString = new Date().toISOString()
            const indexOfT = dateString.indexOf('T');
            const dateWithoutTime = dateString.substring(0, indexOfT);
            const data = {
                reference_date: dateWithoutTime,
                project_id: params.id,
                init_date_time,
                end_date_time,
                description,
            }
            const res = await projectHours.create(data)
            if (!res.error) {
                toast.success('Horas apontadas com sucesso')
            } else {
                toast.error('Erro ao apontar horas')
            }
            handleCloseUser()
            fetchProject()
        } catch (err) {
            toast.error(err)
        }
        setLoadingSave(false)
    }

    useEffect(() => {
        getRefreshHours()
    }, [page]);

    async function getRefreshHours() {
        setLoading(true)
        try {
            const result = await projectHours.list(`project_id=${params.id}&reference=${newReference}`);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }
    }
    async function filter() {
        setLoading(true)
        try {
            const result = await projectHours.list(`project_id=${params.id}&page=${page}&reference=${newReference}`);
            setPaginationData(result.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            toast.error(err)
        }
    }

   
 

    return (
        <div className="dashboard mt-5">
            <Row>
                <Col md={9}>
                    <a href="/e/projects" className="mb-4">Voltar</a>
                    <h2 className="mt-4">{project?.name}</h2>

                </Col>
                <Col md={3}>
                    <ButtonOw variant="primary" className="float-right mt-4" onClick={() => handleShowUser()}>Apontar nova hora</ButtonOw>
                </Col>
            </Row>



            <Row className="mt-4">
                {loading ? loadingPage() :
                    (<>
                        <Row className="mb-4">
                            <Col md={8}>
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <input type="month" min={'2025-01'} defaultValue={newReference} onChange={(e) => setNewReference(e.target.value)} style={{padding:10, border:'none', border: '1px solid #ccc', borderRadius:6}}/>
                                    <button style={{background: 'transparent', border:'none'}} onClick={filter}>Filtrar</button>
                                </div>

                            </Col>
                        </Row>

                        <Tabs
                            defaultActiveKey="hours"
                            id="uncontrolled-tab-example"
                            className="mb-3">
                            <Tab
                                eventKey="hours"
                                title="Horas">
                                <Table hover className="rounded-table">
                                    <thead>
                                        <tr>

                                            <th>Início</th>
                                            <th>Fim</th>
                                            <th>Total de Horas</th>
                                            <th>Descrição</th>
                                            <th style={{ width: '250px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginationData.list.map((item, index) => {
                                            return (
                                                <tr key={index}>

                                                    <td>{item.init_date_time}</td>
                                                    <td>{item.end_date_time}</td>
                                                    <td>{item.total_hours}</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <Col className="action-buttons">
                                                            <span className={`${item.status === 'APPROVED' ? 'text-success' : item.status === 'REJECTED' ? 'text-danger' : ''}`}>
                                                                {item.status === 'WAITING' && 'Aguardando aprovação'}
                                                                {item.status === 'APPROVED' && 'Aprovado'}
                                                                {item.status === 'REJECTED' && 'Não aceito'}
                                                            </span>
                                                            {item.status === 'WAITING' && (<OverlayTrigger placement="top" overlay={tooltip("Deletar Hora")}>
                                                                <a href="#" className="delete mx-3" onClick={() => showModalDelete(item.id)}>
                                                                    <svg width={25} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                    </svg>

                                                                </a>
                                                            </OverlayTrigger>)}

                                                        </Col>


                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Tab>

                        </Tabs>
                    </>
                    )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center mb-4">

                        <h5 className="mb-4 mt-3">Você tem certeza que <br />deseja apagar esse apontamento de horas?</h5>
                        <ButtonOw variant="danger" onClick={() => removeUser()} loading={loadingSave}>
                            Confirmar
                        </ButtonOw>
                    </Modal.Body>
                </Modal>
                <Modal show={showNewUser} onHide={handleCloseUser}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-left mb-4">

                        <h5 className="mb-4 mt-3">Informe o horário de entrada e saída abaixo.</h5>
                        <Form.Group controlId="initDate" className="mb-4">
                            <Form.Label className="mx-2">Horário de início</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="initDate"
                                className="input"
                                onChange={(e) => setInit(e.target.value)}

                            />
                        </Form.Group>
                        <Form.Group controlId="endDate" className="mb-4">
                            <Form.Label className="mx-2">Horário de fim</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="endDate"
                                className="input"
                                onChange={(e) => setEnd(e.target.value)}

                            />
                        </Form.Group>
                        <Form.Group controlId="description" className="mb-4">
                            <Form.Label className="mx-2">Descrição</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder="Descreva suas atividades"
                                name="description"
                                className="input"
                                onChange={(e) => setDescription(e.target.value)}

                            />
                        </Form.Group>
                        <ButtonOw variant="primary" loading={loadingSave} onClick={() => addHour()}>
                            Apontar horário
                        </ButtonOw>
                    </Modal.Body>
                </Modal>
            </Row>
        </div >
    );
}
